<template>
  <div>
    <div v-for="(line,index) in row[header]" :key="index">{{showedLine(line)}}</div>
  </div>
</template>
<script>
export default {
  props: ["row", "header", "result"],
  methods: {
    showedLine(line) {
      if (typeof this.result.additional.managersKeys[line] != "undefined") {
        line = this.result.additional.managersKeys[line];
      }
      return line;
    },
  },
};
</script>